import { Injectable } from '@angular/core';
import {
  TileDataApiResponse,
  SummaryDataApiResponse,
  TileDataFilter,
  TileDataFilterApiResponse,
  TileDataRequestParams,
  FilterStateConfig,
  DropDownParamInfo,
  DropDownAPIResponse,
  PrintCasesQueryParams,
  TodayProgress
} from '../models/materials-management-dashboard.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AdamConf } from '@app/app.config';
import { BaseService } from '@app/core/base.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialsManagementService {
  private adamLabels = AdamConf;
  public inventoryTileFilterState$: BehaviorSubject<TileDataFilter[]>;
  public boxesOrderedReplenishedTileFilterState$: BehaviorSubject<TileDataFilter[]>;
  public dispensedRestockedTileFilterState$: BehaviorSubject<TileDataFilter[]>;
  public filterPopupState$: BehaviorSubject<FilterStateConfig>;
  public dispensedRestockFilter$: BehaviorSubject<TileDataFilter[]>;
  public restockingData$: BehaviorSubject<TodayProgress>;
  public replenishmentData$: BehaviorSubject<TodayProgress>;
  public orderingData$: BehaviorSubject<TodayProgress>;
  public pickingData$: BehaviorSubject<TodayProgress>;
  public transfersData$: BehaviorSubject<TodayProgress>;

  constructor(private readonly baseService: BaseService) {
    this.inventoryTileFilterState$ = new BehaviorSubject<TileDataFilter[]>([]);
    this.boxesOrderedReplenishedTileFilterState$ = new BehaviorSubject<TileDataFilter[]>([]);
    this.dispensedRestockedTileFilterState$ = new BehaviorSubject<TileDataFilter[]>([]);
    this.filterPopupState$ = new BehaviorSubject<FilterStateConfig>({
      isDisplayFilter: false,
      tile: ''
    });
    this.dispensedRestockFilter$ = new BehaviorSubject<TileDataFilter[]>([]);
    this.replenishmentData$ = new BehaviorSubject<TodayProgress>({
      tileName: 'Replenishment Summary',
      pieValue: '0'
    });
    this.orderingData$ = new BehaviorSubject<TodayProgress>({
      tileName: 'Ordering Summary',
      pieValue: '0'
    });
    this.pickingData$ = new BehaviorSubject<TodayProgress>({
      tileName: 'Picking Summary',
      pieValue: '0'
    });
    this.restockingData$ = new BehaviorSubject<TodayProgress>({
      tileName: 'Restocking Summary',
      pieValue: '0'
    });
    this.transfersData$ = new BehaviorSubject<TodayProgress>({
      tileName: 'Transfers Summary',
      pieValue: '0'
    });
}


  public setTileDataFilters(tileName: string, data: TileDataFilter[]): void {
    switch (tileName) {
      case this.adamLabels.materialManagement_module.inventoryTileName:
        this.inventoryTileFilterState$.next(data);
        break;
      case this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName:
        this.boxesOrderedReplenishedTileFilterState$.next(data);
        break;
      case this.adamLabels.materialManagement_module.dispensedRestockedTileName:
        this.dispensedRestockedTileFilterState$.next(data);
        break;
    }
  }

  public getTileDataFilters(tileName: string): Observable<TileDataFilterApiResponse> {
    tileName = encodeURIComponent(tileName);
    return this.baseService.getViaParam('analytics-management/api/v1/ControlTower/TileDataFilters?tileName=' + tileName, null);
  }

  public getTileData(params: TileDataRequestParams): Observable<TileDataApiResponse> {
    if (params.tileFilters.length > 0) {
      return this.baseService.postViaObjectParam('analytics-management/api/v1/ControlTower/TileData', params);
    } else {
      return of();
    }
  }

  public setFilterPopupState(data: FilterStateConfig): void {
    this.filterPopupState$.next(data);
  }

  public getSummaryDataFilters(tileName: string): Observable<SummaryDataApiResponse> {
    let filterEndPoint = 'analytics-management/api/v1/ControlTower/HospitalKpis?area=';
    switch (tileName) {
      case 'Replenishment':
        filterEndPoint += 'Replenishment%20Summary';
        break;
      case 'Restocking':
        filterEndPoint += 'Restocking%20Summary';
        break;
      case 'Ordering':
        filterEndPoint += 'Ordering%20Summary';
        break;
      case 'Picking':
        filterEndPoint += 'Picking%20Summary';
        break;
      case 'Transfers':
        filterEndPoint += 'Transfers%20Summary';
        break;
    }
    return this.baseService.getViaParam(filterEndPoint, null);
  }

  public fetchTileFilteredDropdownList(url: string, data: DropDownParamInfo): Observable<DropDownAPIResponse> {
    return this.baseService.postViaObjectParam(
      'analytics-management/api/v1/' + url,
      data
    );
  }

  public getCaseStatusList(queryParams: PrintCasesQueryParams): Observable<any> {
    return this.baseService.postViaObjectParam('pick-request-management/api/v1/pick-requests/summary/case-list', queryParams);
  }

  public setTodaysProgressTrackerData(pieChartData: TodayProgress): void {
    switch (pieChartData.tileName) {
      case 'Picking Summary':
        this.pickingData$.next(pieChartData);
        break;
      case 'Restocking Summary':
        this.restockingData$.next(pieChartData);
        break;
      case 'Ordering Summary':
        this.orderingData$.next(pieChartData);
        break;
      case 'Replenishment Summary':
        this.replenishmentData$.next(pieChartData);
        break;
      case 'Transfers Summary':
        this.transfersData$.next(pieChartData);
        break;
    }
  }
}
