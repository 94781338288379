<div class="login">
  <div class="row">
    <div [ngClass]="{'offset-xl-2 offset-lg-1 offset-md-0 offset-sm-0 col-lg-6 col-xl-4 col-md-5 pl-0 pr-0 col-sm-6 col-xs-10 login_pad': isUserSetup === false}">

      <div class="pl-3">
        <div class="formContainer">
          @if (!isUserSetup) {
            <h1 class="header1 f-60 mt-8">{{adam_labels.user_management_module.signinHeader}}</h1>
          }
          @if (isUserSetup) {
            <h1 class="header1 f-60 setup_login setup_mt8">{{adam_labels.user_management_module.signinSetupHeader}}</h1>
          }
          @if (isUserSetup) {
            <p class="regular font_siz">{{adam_labels.user_management_module.signinSetupSubHeader}}</p>
          }
          @if (invalidUserOrPwd) {
            <div class="mt-5 header5 text-danger">
              {{signInErrorMsg}}
            </div>
          }

          <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()" [ngClass]="{'user-setup-form': isUserSetup === true}">
            <div [ngClass]="isUserSetup === true ? 'email-usersetup' : 'mt-style-email'">
              <mat-form-field class="width-hundred" appearance="fill">
                <!-- <label for="email"></label> -->
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" type="text" id="email" width="100%"
                  class="fadeIn second" maxlength="100" autocomplete="off" spellcheck="false">
                </mat-form-field>
                @if (f['email'].errors) {
                  <div class="form-errors">
                    @if (submitted && f['email'].errors?.['required']) {
                      <div>{{adam_labels.error_messages.emailRequiredError}}</div>
                    }
                    @if (submitted && loginForm.controls['email'].dirty && f['email'].errors?.['pattern']) {
                      <div>
                      {{adam_labels.error_messages.emailValidationError}}</div>
                    }
                  </div>
                }
              </div>
              <div class="mt-style-password" [ngClass]="{'mt-password-user-setup': isUserSetup === true}">
                <mat-form-field appearance="fill">
                  <!-- <label for="password"></label> -->
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" type="password" id="password"
                    class="fadeIn second" maxlength="100"  onPaste="return false" autocomplete="off">
                  </mat-form-field>
                  @if (f['password'].errors) {
                    <div class="form-errors">
                      @if (submitted && f['password'].errors?.['required']) {
                        <div>Password is required.</div>
                      }
                    </div>
                  }
                </div>

                <div class="forgotpwd">
                  <a (click)="gotoForgotPwd()" class="semibold f-14">{{adam_labels.user_management_module.forgotPwd}}</a>
                </div>
                <div [ngClass]="isUserSetup === true ?'mt-buttons-user-setup':'mt-buttons'">
                  @if (!isUserSetup) {
                    <div class="pull-left">
                      <a (click)="requestAccess()" class="semibold f-14 float-left mt-2 pt-style req-access">{{adam_labels.user_management_module.reqAccessinSignin}}</a>
                    </div>
                  }
                  <div class="pull-right">
                    <a (click)="resetForm()" class="semibold f-14">{{adam_labels.common.cancel}}</a>
                    <input type="submit" class="btn button-primary-large ml-5 margin-left" value={{adam_labels.user_management_module.signinButton}}>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>