import { bootstrapApplication, provideClientHydration } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { provideHttpClient, HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { importProvidersFrom } from '@angular/core';
import { TokenHandlerInterceptor } from '@app/core/token-handler.interceptor';
import { AuthService } from './app/auth/auth.service';
import { OpsAuthService } from './app/ops/ops-auth.service';
import { LoggerService } from './app/core/logger.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { StorageService } from './app/core/storage.service';
import { BaseService } from '@app/core/base.service';
import { provideRouter } from '@angular/router';
import { routes } from '@app/app.routes';
import { ErrorHandlerInterceptor } from '@app/core/error-handler.interceptor';
import { SpinnerHandlerInterceptor } from '@app/core/spinner-handler.interceptor';


function getOrCreateNonce(): string {
  let nonce = localStorage.getItem('appNonce'); // Store in localStorage for persistence
  if (!nonce) {
      nonce = btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(16))));
      localStorage.setItem('appNonce', nonce);
  }
  return nonce;
}

// Store the nonce globally so it's accessible across the application
const globalNonce = getOrCreateNonce();
(window as any).globalNonce = globalNonce;

// Apply nonce to existing scripts and styles on page load
document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("script, style, link[rel='stylesheet']").forEach(el => {
      el.setAttribute("nonce", globalNonce);
  });
});

bootstrapApplication(AppComponent, {
    providers: [
        BaseService,
        StorageService,
        LoggerService,
        ...appConfig.providers,
        importProvidersFrom(BrowserAnimationsModule),
        AuthService,
        OpsAuthService,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        provideRouter(routes),
        provideClientHydration(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerHandlerInterceptor,
            multi: true
        },
        provideAnimations(),
        provideHttpClient(),
        provideHttpClient(withInterceptorsFromDi())
    ]
}).catch((err) => console.error(err));
