import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { MaterialsManagementService } from '../../materials-management/materials-management.service';
import { ColModel, UserData, PickRequest, PrintCasesQueryParams } from '../../models/materials-management-dashboard.model';
import { AdamConf } from '@app/app.config';
import { AuthService } from 'src/app/auth/auth.service';
import { StorageService } from 'src/app/core/storage.service';
import { LoggerService } from '@app/core/logger.service';
import { BaseService } from 'src/app/core/base.service';
import { MatCardModule } from '@angular/material/card';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import{PickRequestPipe} from 'src/app/shared/pipes/pick-request.pipe';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-core-dashboard-materials-management-picking-summary-tile',
  standalone: true,
  imports: [ CommonModule,
    MatCardModule,
    TabViewModule,
    TableModule,
    TooltipModule,PickRequestPipe,
    TitleCasePipe],
  templateUrl: './picking-summary-tile.component.html',
  styleUrls: ['./picking-summary-tile.component.scss']
})
export class PickingSummaryTileComponent implements OnInit, OnDestroy {

  public dataSubscription$: Subscription;
  public pickingValue: string;
  public smartShelfValue: string;
  public kioskValue: string;
  public partialValue: string;
  public colsWIP: ColModel[];
  public colsCompleted: ColModel[];
  public adamLabels = AdamConf;
  private ticker$: Subscription;
  public caseStatusListSubscription$: Subscription;
  public queryParamsForPrintAll: PrintCasesQueryParams;
  public userData: UserData;
  public WIPData: PickRequest[];
  public completedData: PickRequest[];
  public pickRequests: PickRequest[];
  public pickedRequestPrintList: PickRequest[];
  public tileTitle: string;
  public setTime: number;
  public smartShelfTitle: string;
  public kioskTitle: string;
  public partialTitle: string;
  public pickingSummaryTotalCaseCount: string;
  public pickingSummaryCompletedCaseCount: string;
  public coverageKioskCount: string;
  public coverageShelfCount: string;
  public coveragePartialCount: string;
  public printArray: any;
  public isPrintEnabled: boolean;
  picksheetPrint: any[];
  groupedCasesPrint: any[];
  smartRoomValue: string;
  smartRoomTitle: string;
  coverageSmartRoomCount: string;

  constructor(
    private readonly materialsManagementService: MaterialsManagementService,
    private readonly authservice: AuthService,
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly logger: LoggerService,
    private baseService: BaseService,
    private readonly cdr: ChangeDetectorRef

  ) { }

  public ngOnInit(): void {
    this.setTime = 500;
    this.colsWIP = this.colsCompleted = [
      {
        field: this.adamLabels.materialManagement_module.pickingTileTableField1,
        header: this.adamLabels.materialManagement_module.pickingTileTableTitle1,
        width: '30%'
      },
      {
        field: this.adamLabels.materialManagement_module.pickingTileTableField2,
        header: this.adamLabels.materialManagement_module.pickingTileTableTitle2,
        width: '27%'
      },
      {
        field: this.adamLabels.materialManagement_module.pickingTileTableField3,
        header: this.adamLabels.materialManagement_module.pickingTileTableTitle3,
        width: '25%'
      },
      {
        field: this.adamLabels.materialManagement_module.pickingTileTableField4,
        header: this.adamLabels.materialManagement_module.pickingTileTableTitle4,
        width: '18%'
      }
    ];
    this.tileTitle = '';
    this.WIPData = [];
    this.completedData = [];
    this.pickedRequestPrintList = [];
    this.pickRequests = [];

    const userData = this.authservice.getUserData();

    if (userData) {
      this.userData = userData;
      this.getDataForSummaryTile(this.adamLabels.materialManagement_module.pickingTile);
      this.loadCases();
      this.setAutoRefresh();
    } else {
    }

  }
  

  private setAutoRefresh(): void {
    const currentUser = this.authservice.getCurrentUser();
    if (currentUser && currentUser.isAutoRefreshEnabled === true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval ?? 60000; // Default to 60 seconds if undefined
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing Picking summary tile data');
        this.getDataForSummaryTile(this.adamLabels.materialManagement_module.pickingTile);
        this.loadCases();
      });
    } else {
    }

  }
  

  public getClass(): string {
    if (this.partialTitle && this.smartRoomTitle) {
      return 'col-xl-3 col-lg-3 col-md-3 mb-1 column';
    } else if (this.partialTitle || this.smartRoomTitle) {
      return 'col-xl-4 col-lg-4 col-md-4 mb-1 column';
    } else {
      return 'col-xl-5 col-lg-5 col-md-5 mb-1 column';
    }

  }

  private getDataForSummaryTile(tileName: string): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    this.dataSubscription$ = this.materialsManagementService.getSummaryDataFilters(tileName).subscribe((dataItems) => {
      if (dataItems.responseCode === '2001') {
        this.tileTitle = dataItems.responseData.area;
        if (dataItems.responseData.keyValueItems && dataItems.responseData.keyValueItems.length > 0) {
          dataItems.responseData.keyValueItems.forEach((tileData) => {
            switch (tileData.key) {
              case this.adamLabels.materialManagement_module.pickingSummaryTotalCases:
                this.pickingSummaryTotalCaseCount = tileData.value;
                break;
              case this.adamLabels.materialManagement_module.pickingSummaryCompletedCases:
                this.pickingSummaryCompletedCaseCount = tileData.value;
                break;
              case this.adamLabels.materialManagement_module.pickingPercentage:
                this.pickingValue = (parseFloat(tileData.value)).toFixed() + '%';
                this.materialsManagementService.setTodaysProgressTrackerData({
                  tileName: 'Picking Summary',
                  pieValue: (parseFloat(tileData.value)).toFixed()
                });
                break;
              case this.adamLabels.materialManagement_module.coverageKiosk:
                this.kioskValue = (parseFloat(tileData.value)).toFixed() + '%';
                this.kioskTitle = tileData.key;
                break;
              case this.adamLabels.materialManagement_module.coverageKioskCount:
                this.coverageKioskCount = tileData.value;
                break;
              case this.adamLabels.materialManagement_module.coverageShelf:
                this.smartShelfValue = (parseFloat(tileData.value)).toFixed() + '%';
                this.smartShelfTitle = tileData.key;
                break;
              case this.adamLabels.materialManagement_module.coverageShelfCount:
                this.coverageShelfCount = tileData.value;
                break;
              case this.adamLabels.materialManagement_module.coveragePartial:
                this.partialValue = (parseFloat(tileData.value)).toFixed() + '%';
                this.partialTitle = tileData.key;
                break;
              case this.adamLabels.materialManagement_module.coveragePartialCount:
                this.coveragePartialCount = tileData.value;
                break;
              case this.adamLabels.materialManagement_module.coverageSmartRoomCount:
                this.smartRoomTitle = this.adamLabels.materialManagement_module.coverageSmartRoom;
                this.coverageSmartRoomCount = tileData.value;
                break;
              default: break;
            }
          });
        }
      }
    });

  }

  private loadCases(): void {
    this.queryParamsForPrintAll = {
      applyPagination: false,
      institutionId: this.userData.institutionId,
      facilityId: this.userData.facilityId,
      sortBy: {
        origin: this.adamLabels.materialManagement_module.ASC
      },
      pageNumber: 0,
      origin: '',
      scheduleCode: '',
      tabFilter: this.adamLabels.materialManagement_module.tomorrow
    };
    if (this.caseStatusListSubscription$) {
      this.caseStatusListSubscription$.unsubscribe();
    }
    this.caseStatusListSubscription$ = this.materialsManagementService.getCaseStatusList(this.queryParamsForPrintAll).subscribe((data) => {
      if (data.responseCode === '2001') {
        this.WIPData = [];
        this.completedData = [];
        this.pickedRequestPrintList = [];
        this.pickRequests = [];
        if (data.responseData.pickRequests && data.responseData.pickRequests.length > 0) {
          this.pickRequests = data.responseData.pickRequests;
          data.responseData.pickRequests.forEach((pickRequest) => {
            if (pickRequest.status === this.adamLabels.materialManagement_module.retrieveFromKiosk
              || pickRequest.status === this.adamLabels.materialManagement_module.retrieveFromShelf
              || pickRequest.status === this.adamLabels.materialManagement_module.kioskInitiated
              || pickRequest.status === this.adamLabels.materialManagement_module.error
              || pickRequest.status === this.adamLabels.materialManagement_module.interrupted 
              || pickRequest.status === this.adamLabels.materialManagement_module.retrieveFromSmartRoom
              || pickRequest.status === this.adamLabels.materialManagement_module.partiallyRetrieved
              || pickRequest.status === this.adamLabels.materialManagement_module.retrieveFromMultipleDevices) {
                pickRequest.quantity = 0;
                if (pickRequest.pickRequestItems && pickRequest.pickRequestItems.length > 0) {
                  pickRequest.statusDefinition = '';
                  pickRequest.haveSmartRoomProducts = false;
                  pickRequest.pickRequestItems.forEach(a => pickRequest.quantity += a.orderedQuantity);
                  pickRequest.pickRequestItems.forEach((pickRequestItem) => {
                    pickRequestItem.productImage = this.getProductImageUrl(pickRequestItem.productCode);
                  });
                }
                this.WIPData.push(pickRequest);
              } else if (pickRequest.status === this.adamLabels.materialManagement_module.closed
                || pickRequest.status === this.adamLabels.materialManagement_module.retrieved) {
                pickRequest.quantity = 0;
                if (pickRequest.pickRequestItems && pickRequest.pickRequestItems.length > 0) {
                  pickRequest.pickRequestItems.forEach(a => pickRequest.quantity += a.orderedQuantity);
                  pickRequest.pickRequestItems.forEach((pickRequestItem) => {
                    pickRequestItem.productImage = this.getProductImageUrl(pickRequestItem.productCode);
                  });
                }
                pickRequest.order = 1;
                pickRequest.schedules = '';
                pickRequest.total = 0;
                pickRequest.rowSpan = 0;
                this.completedData.push(pickRequest);
              }
            });
            this.checkPrint(this.WIPData);
          }
        }
      });
  }
  
    public checkPrint(data) {
      if (data.length > 0) {
        this.printArray = data.filter((elem) => {
          if (elem && elem.status !== this.adamLabels.materialManagement_module.interrupted && 
            elem.status !== this.adamLabels.materialManagement_module.closed && 
            elem.status !== this.adamLabels.materialManagement_module.retrieved && 
            elem.status !== this.adamLabels.materialManagement_module.error &&
            elem.origin != this.adamLabels.materialManagement_module.smartRoom) {
            return true;
          } else {
            return false;
          }
        });
        if (this.printArray.length > 0) {
          this.isPrintEnabled = true;
        }
      } else {
        this.isPrintEnabled = false;
      }
  
    }
  
    public goToScheduleDetails(scheduleCode: string): void {
      this.router.navigate(['pick-request/product-list', scheduleCode]);
  
    }
  
    public printOrder(): void {
      if (this.pickRequests) {
        this.pickedRequestPrintList = this.pickRequests.filter((elem) => {
          if (elem.status === 'RETRIEVE FROM KIOSK') {
            elem.statusDefinition = this.adamLabels.caseStatus_module.readyForRetrievalDefinition;
          } else if (elem.status === 'RETRIEVE FROM SHELF') {
            elem.statusDefinition = this.adamLabels.caseStatus_module.readyForManualPickDefinition;
          } else if (elem.status === 'KIOSK INITIATED') {
            elem.statusDefinition = this.adamLabels.caseStatus_module.pickingDefinition;
          } else if (elem.status === this.adamLabels.caseStatus_module.retrieved) {
            elem.statusDefinition = this.adamLabels.caseStatus_module.retrievedDefinition;
          } else if (elem.status === this.adamLabels.caseStatus_module.closed) {
            elem.statusDefinition = this.adamLabels.caseStatus_module.closedDefinition;
          } else if (elem.status === this.adamLabels.caseStatus_module.error) {
            elem.statusDefinition = this.adamLabels.caseStatus_module.errorDefinition;
          } else if (elem.status === this.adamLabels.caseStatus_module.interrupted) {
            elem.statusDefinition = this.adamLabels.caseStatus_module.interruptedDefinition;
          } else if (elem.status === 'RETRIEVE FROM SMART ROOM') {
            elem.statusDefinition = this.adamLabels.caseStatus_module.readyForPickDefinition;
          } else if (elem.isPartialPick && !elem.isPartialSmartRoomPick) {
            if (elem.status === 'RETRIEVE FROM MULTIPLE DEVICES') {
              if (!elem.isKioskRetrieved && !elem.isShelfRetrieved) {
                elem.statusDefinition = 'The case is ready to be picked from KIOSK and SMART SHELF';
              }
            } else if (elem.status === 'PARTIALLY RETRIEVED') {
              if (elem.isKioskRetrieved && !elem.isShelfRetrieved) {
                elem.statusDefinition = this.adamLabels.caseStatus_module.kioskRetrievedDefinition;
              } else if (!elem.isKioskRetrieved && elem.isShelfRetrieved) {
                elem.statusDefinition = this.adamLabels.caseStatus_module.shelfRetrievedDefinition;
              }
            }
          } else if (!elem.isPartialPick && elem.isPartialSmartRoomPick) {
            if (elem.status === 'RETRIEVE FROM MULTIPLE DEVICES') {
              if (!elem.isKioskRetrieved && !elem.isSmartRoomRetrieved && !elem.isShelfRetrieved) {
                if (elem.origin === 'KIOSK') {
                  elem.statusDefinition = 'The case is ready to be picked from SMART ROOM and KIOSK';
                } else if (elem.origin === 'SMART_SHELF') {
                  elem.statusDefinition = 'The case is ready to be picked from SMART ROOM and SHELF';
                }
              }
            } else {
              if (elem.isKioskRetrieved && !elem.isSmartRoomRetrieved) {
                elem.statusDefinition = this.adamLabels.caseStatus_module.KioskRetrievedSmartRoomPendingDefinition;
              } else if (elem.isShelfRetrieved && !elem.isSmartRoomRetrieved) {
                elem.statusDefinition = this.adamLabels.caseStatus_module.ShelfRetrievedSmartRoomPendingDefinition;
              } else if (elem.isSmartRoomRetrieved && !elem.isShelfRetrieved && !elem.isKioskRetrieved) {
                if (elem.origin === 'KIOSK') {
                  elem.statusDefinition = this.adamLabels.caseStatus_module.smartRoomRetrievedKioskPendingDefinition;
                } else if (elem.origin === 'SMART_SHELF') {
                  elem.statusDefinition = this.adamLabels.caseStatus_module.smartRoomRetrievedShelfPendingDefinition;
                }
              }
            }
          } else if (elem.isPartialPick && elem.isPartialSmartRoomPick) {
            if (elem.status === 'RETRIEVE FROM MULTIPLE DEVICES') {
              if (!elem.isKioskRetrieved && !elem.isSmartRoomRetrieved && !elem.isShelfRetrieved) {
                elem.statusDefinition = 'The case is ready to be picked from SMART ROOM, KIOSK and SHELF';
              }
            }
            if (elem.isKioskRetrieved && !elem.isSmartRoomRetrieved && !elem.isShelfRetrieved) {
              elem.statusDefinition = this.adamLabels.caseStatus_module.kioskRetrievedSSRPending;
            } else if (elem.isShelfRetrieved && !elem.isSmartRoomRetrieved && !elem.isKioskRetrieved) {
              elem.statusDefinition = this.adamLabels.caseStatus_module.shelfRetrievedKSRPending;
            } else if (elem.isSmartRoomRetrieved && !elem.isShelfRetrieved && !elem.isKioskRetrieved) {
              elem.statusDefinition = this.adamLabels.caseStatus_module.smartRoomRetrievedKSPending;
            } else if (elem.isShelfRetrieved && elem.isKioskRetrieved && !elem.isSmartRoomRetrieved) {
              elem.statusDefinition = this.adamLabels.caseStatus_module.smartRoomPendingKSRetrieved;
            } else if (elem.isSmartRoomRetrieved && elem.isKioskRetrieved && !elem.isShelfRetrieved) {
              elem.statusDefinition = this.adamLabels.caseStatus_module.shelfPendingKSRRetrieved;
            } else if (!elem.isKioskRetrieved && elem.isShelfRetrieved && elem.isSmartRoomRetrieved) {
              elem.statusDefinition = this.adamLabels.caseStatus_module.kioskPendingSSRRetrieved;
            }
          }
          if (elem && elem.status !== this.adamLabels.materialManagement_module.interrupted && 
            elem.status !== this.adamLabels.materialManagement_module.closed && 
            elem.status !== this.adamLabels.materialManagement_module.retrieved && 
            elem.status !== this.adamLabels.materialManagement_module.error &&
            elem.origin != 'SMART_ROOM') {
            elem.pickRequestItems = elem.pickRequestItems.filter(item => {
              if (item.origin == 'SMART_ROOM') {
                elem.haveSmartRoomProducts = true;
              }
              return item.origin != 'SMART_ROOM';
            });
            return true;
          } else {
            return false;
          }
        });
    
        this.picksheetPrint = [];
        this.picksheetPrint = this.pickedRequestPrintList.map(row => row.opreationRoomNameAndSurgeryDateTime);
        this.picksheetPrint = this.picksheetPrint.filter((item, index) => this.picksheetPrint.indexOf(item) === index);
        this.groupedCasesPrint = [];
        for (let i = 0; i < this.picksheetPrint.length; i++) {
          let picksheetPrintSubList = this.pickedRequestPrintList.filter(row => row.opreationRoomNameAndSurgeryDateTime === this.picksheetPrint[i]);
          if (picksheetPrintSubList.length === 0) {
            continue;
          }
          let schedule = picksheetPrintSubList.map(item => item.scheduleCode).join(', ');
          picksheetPrintSubList[0].rowSpan = picksheetPrintSubList.length;
          picksheetPrintSubList.forEach((item, index) => {
            item.order = index + 1;
            item.total = picksheetPrintSubList.length > 1 ? picksheetPrintSubList[0].rowSpan : 1;
            item.schedules = schedule;
          });
          this.groupedCasesPrint = this.groupedCasesPrint.concat(picksheetPrintSubList);
        }
        this.pickedRequestPrintList = this.groupedCasesPrint;
    
        // To get the required object in the new tab print window, setting the formatted data into local storage.
        this.storageService.setItem('printData', JSON.stringify(this.buildPrintObj(this.pickedRequestPrintList)));
    
        // Open print page in new tab
        window.setTimeout(() => {
          window.open('#/pick-request/case-status/print-unit');
        }, this.setTime);
      }
    }
   
  
    private buildPrintObj = (data) => {
      // Final print table data to be passed to print template.
  
      return {
        pageType: 'Case Status All',
        mode: 'all',
        onDate: data.startDateTime,
        name: data.primarySurgeonName,
        operationRoomName: data.operationRoomName,
        procedureName: data.procedureName,
        status: data.status,
        caseNumber: data.scheduleCode,
        tableData: data,
        statusDefinition: data.statusDefinition,
      };
      
    }
  
    private resetPickingSummarySubscriptions(): void {
      if (this.dataSubscription$) {
        this.dataSubscription$.unsubscribe();
      }
      if (this.caseStatusListSubscription$) {
        this.caseStatusListSubscription$.unsubscribe();
      }
  
    }
  
    public ngOnDestroy(): void {
      this.resetPickingSummarySubscriptions();
      if (this.ticker$) {
        this.ticker$.unsubscribe();
      }
    }
  
    getProductImageUrl(productCode) {
  
      return this.baseService.getImageURL('inventory-management/api/v1/public/products/images?productCode=' + productCode);
    }
  }
  