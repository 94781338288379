import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  private globalNonce = (window as any).globalNonce || localStorage.getItem("appNonce");

  loadScript(src: string, async: boolean = true, defer: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) {
        resolve(); // Already loaded
        return;
      }

      const script = document.createElement("script");
      script.src = src;
      script.async = async;
      script.defer = defer;
      script.setAttribute("nonce", this.globalNonce);

      script.onload = () => resolve();
      script.onerror = () => reject(`Failed to load script: ${src}`);

      document.body.appendChild(script);
    });
  }
}
