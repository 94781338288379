import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { CoreService } from '../core.service';
import { RequestAccessAPIResponse, RequestAccessParam, RoleInfo, RoleListAPIResponse, RoleListInfo } from '../models/request-access-model';
import { StorageService } from '../storage.service';
import { CommonModule } from '@angular/common';
import { CaptchaComponent } from '@app/shared/components/captcha/captcha.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DropdownModule } from 'primeng/dropdown';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-request-access',
  standalone: true,
  imports: [CommonModule, CaptchaComponent, ReactiveFormsModule, MatFormFieldModule, DropdownModule, MatInputModule],
  templateUrl: './request-access.component.html',
  styleUrl: './request-access.component.scss'
})
export class RequestAccessComponent implements OnInit {
  showSuccessPage: boolean = false;
  requestAccessForm: FormGroup;
  emailAlreadyExists: boolean = false;
  enteredEmail: string;
  roleData: RoleInfo[];
  roleList: RoleListInfo[];
  submitted: boolean = false;
  showRolePlaceHolder: boolean = false;
  adam_labels: any;
  public isCaptchaValidated = false;
  requestOrigin: any;

  constructor(
    private readonly router: Router,
    private readonly coreService: CoreService,
    private readonly storageService: StorageService) { }

  public ngOnInit(): void {
    this.requestOrigin = this.storageService.getItem('hostName');
    this.adam_labels = AdamConf;
    this.showSuccessPage = false;
    this.getRoleList();
    this.requestAccessForm = new FormGroup({
      name: new FormControl(''),
      lastName: new FormControl(''),
      role: new FormControl(''),
      email: new FormControl('')

    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.requestAccessForm.controls; }

  public requestAccessOnSubmit(): boolean {
    const userAction = this.adam_labels.staff_permission_module.requestAccess;
    this.submitted = true;
    if (!this.validateForm()) {
      return false;
    }
    this.enteredEmail = this.requestAccessForm.value.email;
    let queryParams: RequestAccessParam = {
      "email": this.enteredEmail.toUpperCase(),
      "firstName": this.requestAccessForm.value.name,
      "lastName": this.requestAccessForm.value.lastName,
      "status": "REQUESTED",
      "roleId": this.requestAccessForm.value.role.roleId,
      "requestOrigin": this.requestOrigin
    }
    this.coreService.postReqAccess(queryParams, userAction).subscribe((responseData: RequestAccessAPIResponse) => {
      if (responseData['responseCode'] == "2001") {
        this.showSuccessPage = true;
      }
      else if (responseData['responseCode'] == "4002") {
        if (responseData.responseMessage == "Email requested already exists")
          this.emailAlreadyExists = true;
      }
    });
  }

  public getRoleList(): void {
    this.coreService.getUserGroups().subscribe((roleListRes: RoleListAPIResponse) => {
      this.roleData = roleListRes["responseData"];
      let roleTemp: any[] = [];
      this.roleData.forEach(role => {
        roleTemp.push({ roleId: role.roleId, role: role.roleName, roleDesc: role.roleDescription });
      });
      this.roleList = roleTemp;
    });
  }


  public validateForm(): boolean {
    let valid = true;
    if (this.requestAccessForm.value.email === "") {
      this.requestAccessForm.controls.email.setErrors({ required: true });
      valid = false;
    }
    if (this.requestAccessForm.value.name === "") {
      this.requestAccessForm.controls.name.setErrors({ required: true });
      valid = false;
    }
    if (this.requestAccessForm.value.name !== "") {
      let regEx = new RegExp(/^([A-Za-z0-9]+ )+[A-Za-z0-9]+$|^[A-Za-z0-9]+$/);
      if (!regEx.test(this.requestAccessForm.value.name)) {
        this.requestAccessForm.controls.name.setErrors({ pattern: true });
        valid = false;
      }
    }
    if (this.requestAccessForm.value.lastName !== "") {
      let regEx = new RegExp(/^([A-Za-z0-9]+ )+[A-Za-z0-9]+$|^[A-Za-z0-9]+$/);
      if (!regEx.test(this.requestAccessForm.value.lastName)) {
        this.requestAccessForm.controls.lastName.setErrors({ pattern: true });
        valid = false;
      }
    }
    if (this.requestAccessForm.value.lastName === "") {
      this.requestAccessForm.controls.lastName.setErrors({ required: true });
      valid = false;
    }
    if (this.requestAccessForm.value.role === "") {
      this.requestAccessForm.controls.role.setErrors({ required: true });
      valid = false;
    }
    if (this.requestAccessForm.value.email !== "") {
      let regEx = new RegExp(/^(?!.*?[._\-@]{2})(?!^\.)(?!^\_)(?!^\-)(?!.*[-_.]$)[A-Za-z0-9_.-]+@[A-Za-z0-9.]+\.[A-Za-z]+$/);
      console.log(regEx.test(this.requestAccessForm.value.email));
      if (!regEx.test(this.requestAccessForm.value.email)) {
        this.requestAccessForm.controls.email.setErrors({ pattern: true });
        valid = false;
      }
    }
    if (!this.isCaptchaValidated) {
      valid = false;
    }

    return valid;
  }

  public cancelRequest(): void {
    this.router.navigate(['/home']);
  }

  public showRolePlaceHolderFn(): void {
    this.showRolePlaceHolder = true;
  }

  public handleCaptchaReponse(captchaResponse: string | null): void {
    if (captchaResponse !== null) {
      // TODO
      // set this value based on the API response below, once API is ready
      this.isCaptchaValidated = false;
      this.coreService.validateCaptchaResponse(captchaResponse).subscribe((response) => {
        if (response && response.responseData) {
          this.isCaptchaValidated = response.responseData.success;
        }
      });
    } else {
      this.isCaptchaValidated = false;
    }
  }

}
