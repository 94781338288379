import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NonceService {
  private nonce: string;

  constructor() {
    this.nonce = this.generateNonce();
  }

  private generateNonce(): string {
    return btoa(crypto.getRandomValues(new Uint8Array(16)).toString());
  }

  getNonce(): string {
    return this.nonce;
  }
}
